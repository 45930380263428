<template>
  <div id="page-network-infra" :class="{ 'is-list-collapse': isListCollapse }">
    <div class="infra-list-container">
      <ul class="icon-list">
        <li
          class="icon-list-item"
          v-for="item in iconList"
          :key="item.id"
          :class="{ 'is-selected': item.selected }"
          @click="selectIconItem(item.id)"
        >
          <SvgIconFixedColor
            :icon="`infra-${item.type}-${item.iconCode}`"
            :class="`${item.type}-icon`"
          />
          <span>{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div class="infra-diagram-container">
      <div class="handling-wrapper">
        <BaseRoundedIconButton
          class="handling-btn"
          :icon="isListCollapse ? 'list' : 'expand'"
          @click="toggleList"
        />
        <BaseRoundedIconButton class="handling-btn" :icon="'save'" />
        <BaseRoundedIconButton class="handling-btn" :icon="'download'" />
      </div>
      <div id="myDiagram" ref="myDiagram"></div>
      <div id="myOverviewDiv" ref="myOverviewDiv"></div>
      <ZoomSlider />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import BaseRoundedIconButton from '@/components/Button/BaseRoundedIconButton.vue'
import useNetworkInfraGoJs from '@/composable/useNetworkInfraGoJs'
import ZoomSlider from '@/components/Topology/ZoomSlider.vue'

export default defineComponent({
  components: {
    BaseRoundedIconButton,
    ZoomSlider
  },
  setup() {
    // icon 列表區開合相關：
    const isListCollapse = ref(false)
    const toggleList = () => (isListCollapse.value = !isListCollapse.value)

    // icon 列表資料
    // 注意！icon 名稱由 type 及 iconCode 組成
    // 例如：`infra-${item.type}-${item.iconCode}` -> 'infra-type-0'
    const iconList = ref([
      { id: 0, title: '區域', type: 'type', iconCode: 0, selected: false },
      { id: 1, title: '網段', type: 'type', iconCode: 1, selected: false },
      { id: 2, title: '管理範圍', type: 'type', iconCode: 2, selected: false },
      {
        id: 3,
        title: '監看模式',
        type: 'status',
        iconCode: 0,
        selected: false
      },
      {
        id: 4,
        title: 'IPv4 安全模式',
        type: 'status',
        iconCode: 1,
        selected: false
      },
      {
        id: 5,
        title: 'MAC 安全模式',
        type: 'status',
        iconCode: 2,
        selected: false
      },
      {
        id: 6,
        title: 'Static IP 封鎖政策',
        type: 'status',
        iconCode: 3,
        selected: true
      },
      {
        id: 7,
        title: 'DHCP 服務',
        type: 'status',
        iconCode: 4,
        selected: false
      },
      {
        id: 8,
        title: 'DHCP只派發固定 IP',
        type: 'status',
        iconCode: 4,
        selected: false
      },
      {
        id: 9,
        title: '超出管理範圍封鎖',
        type: 'status',
        iconCode: 5,
        selected: false
      },
      {
        id: 10,
        title: '未知的 IP 派發來源',
        type: 'status',
        iconCode: 6,
        selected: false
      },
      {
        id: 11,
        title: '重要 IP 離線',
        type: 'status',
        iconCode: 7,
        selected: false
      },
      {
        id: 12,
        title: 'IP 衝突',
        type: 'status',
        iconCode: 8,
        selected: false
      },
      {
        id: 13,
        title: 'IPv4 廣播超量',
        type: 'status',
        iconCode: 9,
        selected: false
      },
      {
        id: 14,
        title: 'IPv6 群播超量',
        type: 'status',
        iconCode: 10,
        selected: false
      },
      {
        id: 15,
        title: '超出管理範圍',
        type: 'status',
        iconCode: 11,
        selected: false
      },
      {
        id: 16,
        title: '未授權主機',
        type: 'status',
        iconCode: 12,
        selected: false
      }
    ])

    // 選擇 Icon Item
    const selectIconItem = targetId => {
      // 1. 更新 iconList selected state
      iconList.value.forEach(item => {
        if (item.id == targetId) {
          item.selected = !item.selected
        }
      })
      // 2. FIXME: 更新系統架構圖
    }

    // GOJS 相關
    const { myDiagram, myOverviewDiv, init } = useNetworkInfraGoJs()

    onMounted(() => {
      init(myDiagram, myOverviewDiv)
    })

    return {
      isListCollapse,
      toggleList,
      iconList,
      selectIconItem,
      myDiagram,
      myOverviewDiv
    }
  }
})
</script>

<style scoped lang="scss">
#myDiagram {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
}

#myOverviewDiv {
  position: absolute;
  width: 193px;
  height: 114px;
  left: 16px;
  bottom: 13px;
}
</style>
