import go, { GraphObject } from 'gojs'
import { ref } from 'vue'
import { networkInfrastructure } from '../mock/data/networkInfrastructure'
import siteIcon from '../assets/images/icons-fixed-color/infra-type-0.svg'
import networkIcon from '../assets/images/icons-fixed-color/infra-type-1.svg'
import rangeIcon from '../assets/images/icons-fixed-color/infra-type-2.svg'
import status0 from '../assets/images/icons-fixed-color/infra-status-0.svg'
import status1 from '../assets/images/icons-fixed-color/infra-status-1.svg'
import status2 from '../assets/images/icons-fixed-color/infra-status-2.svg'
import status3 from '../assets/images/icons-fixed-color/infra-status-3.svg'
import status4 from '../assets/images/icons-fixed-color/infra-status-4.svg'
import status5 from '../assets/images/icons-fixed-color/infra-status-5.svg'
import status6 from '../assets/images/icons-fixed-color/infra-status-6.svg'
import status7 from '../assets/images/icons-fixed-color/infra-status-7.svg'
import status8 from '../assets/images/icons-fixed-color/infra-status-8.svg'
import status9 from '../assets/images/icons-fixed-color/infra-status-9.svg'
import status10 from '../assets/images/icons-fixed-color/infra-status-10.svg'
import status11 from '../assets/images/icons-fixed-color/infra-status-11.svg'
import status12 from '../assets/images/icons-fixed-color/infra-status-12.svg'
import pixisCenter from '../assets/images/img/pixiscenter.svg'

const useGoJsInNetworkInfra = () => {
  const myDiagram = ref('')
  const myOverviewDiv = ref('')

  const nodeDataArray = networkInfrastructure.nodeData
  const linkDataArray = networkInfrastructure.linkData
  const GS = go.GraphObject.make

  const pixisTemplate = GS(
    go.Node,
    'Auto',
    { minSize: new go.Size(112, 141) },
    GS(go.Picture, pixisCenter),

    GS(
        'TreeExpanderButton', {
        "ButtonIcon.stroke": "#E4E6EC",
        "ButtonBorder.fill": "#171A1D",
        "ButtonBorder.stroke": "#9B9FA8",
        "_buttonFillOver": "#2A5850",
        "_buttonStrokeOver": "#3DCB97",
        "_buttonFillPressed": "#2F9471",
    },
      { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
      { visible: true }
    )
  )

  const siteTemplate = GS(
    go.Node,
    'Spot',
    {
      resizable: true,
      minSize: new go.Size(215, 77)
    },
    GS(go.Shape, 'RoundedRectangle', {
      fill: '#E4E6EC',
      parameter1: 10,
      stretch: go.GraphObject.Fill
    }),
    GS(
      go.Panel,
      'Table',
      {},
      GS(go.RowColumnDefinition, {
        column: 0,
        sizing: go.RowColumnDefinition.None,
        alignment: go.Spot.Center
      }),
      GS(
        go.Panel,
        'Auto',
        {
          row: 0,
          column: 0,
          margin: new go.Margin(0, 10, 0, 10)
        },
        GS(
          go.Panel,
          'Table',
          GS(go.Picture, siteIcon, {
            column: 0,
            width: 22,
            height: 22,
            margin: new go.Margin(0, 4, 0, 0)
          }),
          GS(
            go.TextBlock,
            {
              column: 1,
              maxSize: new go.Size(160, 30)
            },
            new go.Binding('text', 'name')
          )
        )
      ),
      GS(
        go.Panel,
        'Auto',
        {
          row: 1,
          column: 0,
          stretch: go.GraphObject.Horizontal,
          margin: new go.Margin(12, 20, 8, 20),
          minSize: new go.Size(140, 26)
        },
        GS(
          go.Panel,
          'Table',
          GS(go.Picture, status1, {
            width: 26,
            height: 26,
            column: 0,
            margin: new go.Margin(0, 4, 0, 0)
          }),
          GS(go.Picture, status2, {
            width: 26,
            height: 26,
            column: 1,
            margin: new go.Margin(0, 4, 0, 0)
          }),
          GS(go.Picture, status6, {
            width: 26,
            height: 26,
            column: 2,
            margin: new go.Margin(0, 4, 0, 0)
          }),
          GS(go.Picture, status7, {
            width: 26,
            height: 26,
            column: 3,
            margin: new go.Margin(0, 4, 0, 0)
          })
        )
      )
    ),
    GS(
        'TreeExpanderButton', {
        "ButtonIcon.stroke": "#E4E6EC",
        "ButtonBorder.fill": "#171A1D",
        "ButtonBorder.stroke": "#9B9FA8",
        "_buttonFillOver": "#2A5850",
        "_buttonStrokeOver": "#3DCB97",
        "_buttonFillPressed": "#2F9471",
    },
      { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
      { visible: true }
    )
  )

  const networkTemplate = GS(
    go.Node,
    'Spot',
    {
      resizable: true,
      minSize: new go.Size(180, 77)
    },
    GS(go.Shape, 'RoundedRectangle', {
      fill: '#E4E6EC',
      parameter1: 10,
      stretch: go.GraphObject.Fill
    }),
    GS(
      go.Panel,
      'Table',
      GS(go.RowColumnDefinition, {
        column: 0,
        sizing: go.RowColumnDefinition.None,
        alignment: go.Spot.Center
      }),
      GS(
        go.Panel,
        'Auto',
        {
          row: 0,
          column: 0,
          margin: new go.Margin(0, 10, 0, 10)
        },
        GS(
          go.Panel,
          'Table',
          GS(go.Picture, networkIcon, {
            column: 0,
            width: 22,
            height: 22,
            margin: new go.Margin(0, 4, 0, 0)
          }),
          GS(
            go.TextBlock,
            {
              column: 1,
              maxSize: new go.Size(160, 30)
            },
            new go.Binding('text', 'name')
          )
        )
      ),
      GS(
        go.Panel,
        'Auto',
        {
          row: 1,
          column: 0,
          stretch: go.GraphObject.Horizontal,
          margin: new go.Margin(12, 30, 0, 30),
          minSize: new go.Size(100, 26)
        },
        GS(
          go.Panel,
          'Table',
          GS(go.Picture, status4, { width: 26, height: 26, column: 0 }),
          GS(go.Picture, status5, { width: 26, height: 26, column: 1 }),
          GS(go.Picture, status3, { width: 26, height: 26, column: 2 })
        )
      )
    ),
    GS(
      'TreeExpanderButton', { 
        "ButtonIcon.stroke": "#E4E6EC",
        "ButtonBorder.fill": "#171A1D",
        "ButtonBorder.stroke": "#9B9FA8",
        "_buttonFillOver": "#2A5850",
        "_buttonStrokeOver": "#3DCB97",
        "_buttonFillPressed": "#2F9471",
      },
      { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
      { visible: true }
    )
  )

  const rangeTemplate = GS(
    go.Node,
    'Auto',
    {
      resizable: true,
      minSize: new go.Size(160, 90)
    },
    GS(go.Shape, 'RoundedRectangle', {
      fill: '#E4E6EC',
      parameter1: 10,
      stretch: go.GraphObject.Fill
    }),
    GS(
      go.Panel,
      'Table',
      GS(go.RowColumnDefinition, {
        column: 0,
        sizing: go.RowColumnDefinition.None,
        alignment: go.Spot.Center
      }),
      GS(
        go.Panel,
        'Auto',
        {
          row: 0,
          column: 0,
          margin: new go.Margin(0, 10, 0, 10)
        },
        GS(
          go.Panel,
          'Table',
          GS(go.Picture, rangeIcon, {
            column: 0,
            width: 22,
            height: 22,
            margin: new go.Margin(0, 4, 0, 0)
          }),
          GS(
            go.TextBlock,
            {
              column: 1,
              maxSize: new go.Size(160, 30)
            },
            new go.Binding('text', 'name')
          )
        )
      ),
      GS(
        go.Panel,
        'Auto',
        {
          row: 1,
          column: 0,
          stretch: go.GraphObject.Horizontal,
          margin: new go.Margin(12, 30, 0, 30),
          minSize: new go.Size(100, 26)
        },
        GS(
          go.Panel,
          'Table',
          GS(go.Picture, status12, { width: 26, height: 26, column: 0 }),
          GS(go.Picture, status11, { width: 26, height: 26, column: 1 }),
          GS(go.Picture, status3, { width: 26, height: 26, column: 2 })
        )
      )
    )
  )

  const init = (diagramRef, overviewRef) => {
    const GS = go.GraphObject.make // for conciseness in defining templates
    const myDiagram = GS(go.Diagram, diagramRef.value)

    myDiagram.initialDocumentSpot = go.Spot.Center
    myDiagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray)
    myDiagram.layout = GS(go.TreeLayout, { angle: 90 })

    const templmap = new go.Map()
    templmap.add('PIXISPDI', pixisTemplate)
    templmap.add('Site', siteTemplate)
    templmap.add('Network', networkTemplate)
    templmap.add('Range', rangeTemplate)
    // myDiagram.nodeTemplate = GS(go.Node, 'Auto', GS('TreeExpanderButton'))
    myDiagram.linkTemplate = GS(
      go.Link,
      {
        // 曲線
        curve: go.Link.Bezier,
        toEndSegmentLength: 30,
        fromEndSegmentLength: 30
      },
      GS(go.Shape, { stroke: '#797E89' })
    )

    myDiagram.nodeTemplateMap = templmap
    // Overview
    const myOverview = GS(go.Overview, overviewRef.value, {
      observed: myDiagram,
      contentAlignment: go.Spot.Center
    })
    myOverview.box.elt(0).stroke = '#797E89'
  }

  return {
    myDiagram,
    myOverviewDiv,
    init
  }
}

export default useGoJsInNetworkInfra
